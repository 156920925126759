import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './AboutUs.css';
import { useNavigate} from 'react-router-dom';

function AboutUs() {
  const navigate = useNavigate();
  return (
  <>
  <div className='app__header app__wrapper section__paddi'>   
      <div className="cardex-container" style={{paddingBottom: '1.3em'}} >   
          <div className="cardex">
            <div className='app__wrapper_infoz' style={{alignItems: 'center'}}>
              <h1 className='app__header-h1' style={{marginTop: '-1em', width: '82%'}}>We are working towards a future where households have improved access to and control over public health information</h1>
              <p className='p__opensansx' style={{width: '56%',paddingBottom: '2.2em', marginTop: '-1.8em'}}>InfoAFYA&trade; is a versatile digital platform that simplifies the design and implementation of impactful Social Behaviour Change interventions by National and County governments.</p>
              <button type='button' className='custom__button' onClick={()=>{navigate('/company')}}>Learn More</button>
            </div>
          </div>
      </div>
    </div>   
  </>
  )
}

export default AboutUs;
