import React from 'react';
import CountUp from 'react-countup';
import { images } from '../../constants';
import { useNavigate } from 'react-router-dom';

import './OurWork.css';

export const OurWork= () => {
const navigate = useNavigate();
  return (
    <>
    <div className='headText__cormorant'>
            <h2 className='headtexta__cormorant' style={{paddingTop: '0.6em', marginBottom: '0.1em'}}>Our Work</h2>
        </div>
        <div className="cardx-container" style={{paddingBottom: '5em'}}>
            
            <div className="cardx">
                {/* <img src={images.find} alt='findus' /> */}
                <div className="cardx-content">
                    <div>
                        <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '1.57em',fontWeight: '500', color: '#000'}}><a href='#company' onClick={()=>{navigate('/soli')}}>The DPE product Masterplan</a></p>
                    </div>
                    <div>
                        <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>06 Feb 2024</p>
                    </div>
                </div>
            </div>
            <div className="cardx">
                {/* <img src={images.find} alt='findus' /> */}
                <div className="cardx-content">
                    <div>
                        <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '1.57em',fontWeight: '500', color: '#000'}}><a href='#company' onClick={()=>{navigate('/soliii')}}>AI for Social Behavior Change</a></p>
                    </div>
                    <div>
                        <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>11 Feb 2024</p>
                    </div>
                </div>
            </div>
            <div className="cardx">
                <div className="cardx-content">
                    <div>
                        <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '1.57em',fontWeight: '500', color: '#000'}}><a href='#company' onClick={()=>{navigate('/solii')}}>The InfoAFYA&trade; Manifesto</a></p>
                    </div>
                    <div>
                        <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>09 Feb 2024</p>
                    </div>
                </div>
            </div>

        </div>








    
    {/* <div className='app__gala app__b'>
        <div className='headTexti__cormorant'>
            <h2 className='p__cormorante' style={{marginRight: '28em', fontWeight: '600'}}>Our Work</h2>
        </div>
        <div className='app__galla flex__center'>
            <div className='flexCenterd statsz'>
                <div className="flexColCenterzi stat">
                    <div className='app__wrapper_imgz'>
                        <p className='p__opel' style={{marginRight: '2em', fontSize: '22px', padding: '0.4em',fontWeight: '550', color: '#000'}}><a href='#company' onClick={()=>{navigate('/soli')}}>The DPE product Masterplan</a></p>
                    </div>
                    <div className='app__wrapper_imgz'>
                        <p className='p__opex' style={{ color: '#79867b',fontWeight: '400'}}>06 Feb 2024</p>
                    </div>
                </div>
                
                <div className="flexColCenterzi stat">
                    <div className='app__wrapper_imgz'>
                        <p className='p__opel' style={{marginRight: '2em', fontSize: '22px', padding: '0.4em', fontWeight: '550', color: '#000'}}><a href='#company' onClick={()=>{navigate('/soliii')}}>AI for Social Behavior Change </a></p>
                    </div>
                    <div className='app__wrapper_imgz'>
                        <p className='p__opex' style={{ color: '#79867b', fontWeight: '400'}}>11 Feb 2024</p>
                    </div>
                </div>

                <div className="flexColCenterzi stat">
                    <div className='app__wrapper_imgz'>
                        <p className='p__opel' style={{marginRight: '2em', fontSize: '22px', padding: '0.4em',fontWeight: '550', color: '#000'}}><a href='#company' onClick={()=>{navigate('/solii')}}>InfoAFYA&trade; manifesto</a></p>
                    </div>
                    <div className='app__wrapper_imgz'>
                        <p className='p__opex' style={{ color: '#79867b', fontWeight: '400'}}>09 Feb 2024</p>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </>
 )
}
