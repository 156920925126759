import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Values.css';

import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu,  MdOutlineClose } from 'react-icons/md';
import Gallery  from '../../container/Gallery/Gallery';
import { Navbar } from '../../components';





const Values = () => (
  <>

<div className='app__header app__wrapper section__padding' id='company'>
    <div className='app__wrapper_info' style={{alignItems: 'center'}}>
      
      <div className='container app__wrapperzia app__bgi section__paddings' id='company'>
        <div className='contains'>

            <div className='flex-item item-1 app__wrapper_ion onez'>
              <h2 className='headtext__cormorantis' style={{fontSize: '2.4em', width: '12em', marginTop: '1em'}}>Our Values</h2> 
            </div>
            

            <div className='flex-item item-2 twoz' style={{marginLeft: '0em'}}>
              <div className='app__chef-contenti'>
                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  <div>
                    <p className='p__opensz'>Here for the mission</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', paddingRight: '0.8em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>DPE's mission is to empower individuals, households and communities to take control of
                  their health. We pursue our mission by building reliable systems, examining their
                  efficacy, working to responsibly deploy them and regularly sharing
                  our insights. We collaborate with other stakeholders seeking similar population health outcomes.</p>
                  </div>
                </div>

                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  <div>
                    <p className='p__opensz'>Inclusive by design</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', paddingRight: '0.2em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We ensure that our products are accessible to all users, regardless of abilities or backgrounds. This involves
                  user-centered design, technology accessibility and business
                  strategies that promote diversity and are inclusive for everyone.</p>
                  </div>
                </div>
              </div>

              <div className='app__chef-contenti'>
                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  <div>
                    <p className='p__opensz'>Inherent simplicity</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', paddingRight: '0.8em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We strive to create products and services
                    that are simple and easy to use. By simplifying complex processes, we empower health systems to achieve their goals with
                    greater efficiency and effectiveness.</p>
                  </div>
                </div>

                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  <div>
                    <p className='p__opensz'>Radical candor</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', paddingRight: '0.2em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>At DPE, we are willing to challenge assumptions, take calculated risks, and embrace failure as an
                    opportunity to learn and grow. We love finding out what works by trying it and applying this to our research, engineering and partnerships.</p>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
          
      </div>



    </div>
  </div>
 
  </>
);

export default Values;
