import images from "./images";

const interch = [
  {
    title: "Interch, a Personalization engine for Digital Health.",
    price: "",
    tags: "",
  },
];
const infoafya = [
  {
    title:
      "InfoAFYA, Kenya's first household health assistant Supporting Universal Health Coverage in Kenya.",
    price: "",
    tags: "",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Bib Gourmond",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award03,
    title: "Outstanding Chef",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
];

// const items = [
//   {
//     imgUrl: images.prof,
//     title: 'Bib Gourmond',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },
//   {
//     imgUrl: images.prof,
//     title: 'Rising Star',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },
//   {
//     imgUrl: images.prof,
//     title: 'AA Hospitality',
//     subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
//   },

// ];

export default { awards, interch, infoafya };
