import React, { useState } from 'react';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu,  MdOutlineClose } from 'react-icons/md';
import Gallery  from '../../container/Gallery/Gallery';


import images from '../../constants/images';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  
  return(
    <>
      
    {/* <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <a href='#home' onClick={()=>{navigate('/')}}><img src={images.gericht} alt='app logo' /></a>
      </div>
      
      <ul className='app__navbar-links'>
        <li  className='p__opens'><a href='#home' onClick={()=>{navigate('/')}}>Home</a></li>
        <li  className='p__opens'><a href='#company' onClick={()=>{navigate('/company')}}>Company</a></li>
        <li  className='p__opens'><a href='#blog' onClick={()=>{navigate('/blog')}}>Insights</a></li>
      </ul>
      <div className='app__navbar-smallscreen'>
        <GiHamburgerMenu  color='#000' fontSize={27} onClick={() => setToggleMenu(true)}/>

        {toggleMenu && (
          <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
            <MdOutlineClose fontSize={27} className='overlay__close' onClick={() => setToggleMenu(false)} />
            <ul className='app__navbar-smallscreen_links'>
              <li  className='p__opens'><a href='/'>Home</a></li>
              <li  className='p__opens'><a href='/company'>Company</a></li>
              <li  className='p__opens'><a href='/blog'>Insights</a></li>
            </ul>
          </div>
        )}
        
      </div>

    </nav> */}
    {/* <Outlet /> */}

    <div className='app__header app__bg app__wrappery section__paddi' id='home'>
      <div className="cardal-container" style={{paddingBottom: '0.7em'}} >   
          <div className="cardal">
            <div className='app__wrapper_infoz' style={{alignItems: 'center'}}>
                <nav className='app__navbar'>
                  <div className='app__navbar-logo'>
                    <a href='#home' onClick={()=>{navigate('/')}}><img src={images.gericht} alt='app logo' /></a>
                  </div>
                  
                  <ul className='app__navbar-links'>
                    <li  className='p__opens'><a href='#home' onClick={()=>{navigate('/')}}>Home</a></li>
                    <li  className='p__opens'><a href='#company' onClick={()=>{navigate('/company')}}>Company</a></li>
                    <li  className='p__opens'><a href='#blog' onClick={()=>{navigate('/blog')}}>Insights</a></li>
                  </ul>
                  <div className='app__navbar-smallscreen'>
                    <GiHamburgerMenu  color='#000' fontSize={27} onClick={() => setToggleMenu(true)}/>

                    {toggleMenu && (
                      <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
                        <MdOutlineClose fontSize={27} className='overlay__close' onClick={() => setToggleMenu(false)} />
                        <ul className='app__navbar-smallscreen_links'>
                          <li  className='p__opens'><a href='/'>Home</a></li>
                          <li  className='p__opens'><a href='/company'>Company</a></li>
                          {/* <li  className='p__opens'><a href='#company' onClick={()=>{navigate('/company')}}>Company</a></li> */}
                          <li  className='p__opens'><a href='/blog'>Insights</a></li>
                        </ul>
                      </div>
                    )}
                    
                  </div>

                </nav>
            
            
            
            </div>
          </div>
      </div>
      
  </div>



    </>
  )
}

export default Navbar;
