import React from 'react';


import { images } from '../../constants'; 
import './ComHeader.css';
import { SubHeading } from '../../components';
import { useNavigate } from 'react-router-dom';


function ComHeader(){
  const navigate = useNavigate();
  return (
  <>
    <div className='app__header app__wrappery section__paddi' id='company'>   
      <div className="cardie-container" style={{paddingBottom: '1.5em'}} >   
          <div className="cardie" style={{marginBottom: '7em'}}>
            <div className='app__wrapper_infoz' style={{alignItems: 'center'}}>
              <h1 className='app__header-h1' style={{paddingBottom: '0.8em', width: '70%', lineHeight: '1.01em'}}>Developing solutions that serve the health needs of people and communities</h1>
              <p className='p__opensansx' style={{paddingBottom: '1.4em', width: '45%', marginTop: '-1em'}}>We build safe, reliable, and accessible solutions that empower households
                to take control of their health.</p>
              <button type='button' className='custom__button' onClick={()=>{navigate('/')}}>Learn more</button>
            </div>
          </div>
      </div>
    </div>


    


    
  </>
  )
};

export default ComHeader;
