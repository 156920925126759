import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Solvi.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Solvi = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='company'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__headi-h1' style={{width: '800px', margin: '2rem 0', textAlign: 'center'}}>DPE Company team member spotlight: Dennis Maorwe</h1>
        <p className='p__opensans' style={{ margin: '0rem 20rem', textAlign: 'center', }}>13 Feb 2024</p>
        {/* <button type='button' className='custom__button' style={{borderRadius: '12px', margin: '2rem 2rem'}}>Read Paper</button> */}
        
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensansa' style={{ margin: '2rem 5rem', width: '650px'}}>Meet Dennis: Dedicated and Innovative Product leader at DPE Company Ltd. <br/> <br/>
              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>
                <img src={images.cto} alt='footer_logo' style={{width: '12em', height: '12em', borderRadius: '50%'}} />
              </p>

              Dennis is a product innovator at DPE, where he has been a driving force behind the
              Research and Development of the company’s products and services in the last 3 years.
              <br/><br/>

              Dennis has over 13 years of experience working with and within the global business
              software industry, having worked with clients across Africa, Middle East, Southeast Asia, and
              Europe in a wide variety of sectors such as software, government, telecommunications,
              insurance, healthcare, to name a few.
              <br/><br/>

              He has also worked extensively as an independent consultant and is a prominent feature in
              the Kenyan Startup Innovation and Acceleration scene. He holds a post graduate degree in
              Telecommunications Engineering from Derby University, among many other educational
              qualifications.
              <br/><br/>

              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Work Highlights</p>

              Dennis has been with DPE Company Ltd since its inception. Under his leadership, DPE
              Company has recently launched its groundbreaking products InfoAFYA™ and Interch™. His
              ability to identify emerging trends and translate them into innovative solutions within a
              short time is instrumental in driving the company&#39;s growth.
              <br/><br/>

              Dennis serves as a valuable support member within the company, contributing to various
              initiatives by developing essential documentation, participating in the creation of new
              business pitches, and driving organizational development through the creation of innovative
              operating models for the business.
              <br/><br/>

              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>
                &quot;The most rewarding part of my work at DPE is seeing our products make a real difference
                in people&#39;s lives. Knowing that our work is helping to solve real-world problems keeps me
                motivated every day.&quot;
              </p>

              Connect with Dennis on <a style={{color: 'blue'}} href='https://www.linkedin.com/in/dennismutwiri/'>LinkedIn</a> or read about his diverse interests on his <a style={{color: 'blue'}} href='https://dennismaorwe.com/'>personal website.</a>

          </p>
        </div>
        <p className='p__opensans' style={{ margin: '2rem 5rem', width: '600px', borderBottom: '1px solid #000',}}></p>
     </div>
    </div>
  </>
);

export default Solvi;
