import bg from "../assets/bg.png";
import chef from "../assets/ceo.png";
import comp from "../assets/find.png";
import G from "../assets/G.png";
import gallery01 from "../assets/gallery25.png";
import gallery02 from "../assets/gallery22.png";
import gallery03 from "../assets/gallery23.png";
import gallery04 from "../assets/gallery24.png";
import knife from "../assets/therm.png";
import logo from "../assets/logo.png";
import menu from "../assets/steth.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/contactus.jpg";
import findus from "../assets/contactus.jpg";
import laurels from "../assets/laurels.png";
import award01 from "../assets/award01.png";
import award02 from "../assets/award02.png";
import award03 from "../assets/award03.png";
import award05 from "../assets/award05.png";
import sign from "../assets/sign.png";
import quote from "../assets/quote.png";
import gericht from "../assets/blacklogo.png";
import guch from "../assets/dpeLogoz.png";
import guchi from "../assets/white.png";
import gerich from "../assets/interchLog.png";
import geri from "../assets/infoAfyaLog.png";
import geriza from "../assets/kenaam.jpg";
import find from "../assets/contactus.jpg";
import value from "../assets/dunia.jpg";
import moh from "../assets/moh.jpg";
import prof from "../assets/prof.svg";
import val3 from "../assets/r3.jpg";
import val1 from "../assets/r9.jpg";
import val2 from "../assets/pr6.jpg";
import val4 from "../assets/r11.jpg";
import cto from "../assets/denno.png";

export default {
  bg,
  chef,
  val1,
  val2,
  val4,
  cto,
  val3,
  prof,
  guch,
  guchi,
  comp,
  geri,
  geriza,
  value,
  find,
  moh,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  gerich,
};
