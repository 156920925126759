import React from 'react';

import { images } from '../../constants';

const SubHeading = ({ title }) => (
  <div style={{ marginBottom: '1rem'}}>
    <p className='p__cormoranta' style={{fontSize: '3.1em',width: '15em', lineHeight: '1.08em',fontWeight: '610'}}>{title}</p>
    {/* <img src={images.spoon} alt='spoon' className='spoon_img' /> */}
  </div>
);

export default SubHeading;
