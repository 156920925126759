import React from 'react';

import { images, data } from '../../constants';
import { MenuItem } from '../../components';
import './Blog.css';
import { useNavigate } from 'react-router-dom';

function Blog(){
  const navigate = useNavigate();
  return (
    <>
      <div className='app__header app__wrapper section__paddingz' id='blog'>   
          <div className="cardiae-container" style={{paddingBottom: '1em'}} >   
              <div className="cardiae">
                <div className='app__wrapper_infoz blog' style={{alignItems: 'center'}}>
                  <h1 className='app__header-h1' style={{paddingBottom: '0.3em'}}>Our Perspectives</h1>
                  <p className='p__opensansx' style={{paddingBottom: '1.9em', width: '45%'}}>We conduct research and explore emerging areas where technology provides sustainable and desirable population health outcomes.</p>
                  <button type='button' className='custom__button'><a href='#inst'> See more of our perspectives</a></button>
                </div>
              </div>
          </div>
      </div>

      <div className='app__header app__wrapper section__paddingx' style={{paddingTop: '0.1rem'}} id='inst'>
        <div className='app__wrapper_infoz' style={{alignItems: 'center'}}>
            <div className='app__specialMenuz flex__center section__padding'>

                <div className='app__specialMenu-menu inst' style={{marginTop: '1rem'}}>
                  <div className='app__specialMenu-menu_cocktails flex__center'>
                    <h1 className='app__header-h1 fine' style={{paddingTop:'2em', margin: '-4rem 4rem', textAlign: 'center', fontWeight: '600'}}>The DPE product masterplan</h1>
                    <p className='p__opensansx' style={{fontWeight: '500', width: '60%', paddingBottom: '1rem', margin: '1rem 10rem', textAlign: 'center', fontSize: '2em', lineHeight: '1.25em'}}>
                      In the next 5 years, we aim to be the leading comprehensive population health engagement
                      technology platform for end users and providers, engaging with over half of the East African
                      population to reduce the healthcare burden and improve their lives. 
                    </p>
                    <div style={{ marginTop: '2.5rem', marginBottom: '1px'}}>
                      <button type='button' className='custom__button' ><a href='#company' onClick={()=>{navigate('/soli')}}>Read More</a></button>
                    </div>
                  </div>
                </div>            
            </div>
        </div>
      </div>    
      

      <div className='app__def flex__center'>
        <div className='app__specialMenu-menu_cocktailsz'>
                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px'}}>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem'}}>
                    <p className='p__opensans' style={{width: '800px'}} id='in'><a href='#company' onClick={()=>{navigate('/soli')}}>The DPE product Masterplan</a></p>
                  </div>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem 0 1.2rem 0'}}>
                      <p className='p__opensans was' style={{ width: '110px', color: '#AAAAAA'}}>06 Feb 2024</p>
                  </div>
                </div>


                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px'}}>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem'}}>
                    <p className='p__opensans' style={{width: '800px'}}><a href='#company' onClick={()=>{navigate('/solii')}}>
                      InfoAFYA&trade;: A Comprehensive Solution towards Improved
                      Population Health Outcomes</a>
                    </p>
                    
                  </div>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem 0 1.2rem 0'}}>
                      <p className='was p__opensans' style={{ width: '110px', color: '#AAAAAA'}}>09 Feb 2024</p>
                  </div>
                </div>

                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px'}}>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem'}}>
                    <p className='p__opensans' style={{width: '800px'}}><a href='#company' onClick={()=>{navigate('/soliii')}}>Core views on the relevance of AI to Social Behavior Change</a></p>
                  </div>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem 0 1.2rem 0'}}>
                      <p className='was  p__opensans' style={{ width: '110px', color: '#AAAAAA'}}>11 Feb 2024</p>
                  </div>
                </div>
                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px'}}>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem'}}>
                    <p className='p__opensans' style={{width: '800px'}}><a href='#company' onClick={()=>{navigate('/solv')}}>InfoAFYA™ partner spotlight: KeNAAM</a></p>
                  </div>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem 0 1.2rem 0'}}>
                      <p className='was p__opensans' style={{ width: '110px', color: '#AAAAAA'}}>12 Feb 2024</p>
                  </div>
                </div>
                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px'}}>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem'}}>
                    <p className='p__opensans' style={{width: '800px'}}><a href='#company' onClick={()=>{navigate('/solvi')}}>DPE Company team member spotlight: Dennis Maorwe</a></p>
                  </div>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem 0 1.2rem 0'}}>
                      <p className='was p__opensans' style={{ width: '110px', color: '#AAAAAA'}}>12 Feb 2024</p>
                  </div>
                </div>

                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px'}}>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem'}}>
                    <p className='p__opensans' style={{width: '800px'}}><a href='#company' onClick={()=>{navigate('/soliv')}}>Celebrating Our Data Protection Certification from the Office of the Data Protection Commissioner (ODPC) in Kenya</a></p>
                  </div>
                  <div className='app__wrapper_imge' style={{margin: '1.2rem 0 1.2rem 0'}}>
                      <p className='was p__opensans' style={{ width: '110px', color: '#AAAAAA'}}>13 Feb 2024</p>
                  </div>
                </div>

                <div className='app__chef-contentiz' style={{ borderTop: '1px solid #000',width: '1200px', borderBottom: '0px solid #000', marginBottom: '5rem'}}>
                </div>
        </div>
      </div>
      </>

      
      
    )
}

export default Blog;
