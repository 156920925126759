import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Soliii.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Soliii = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='company'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__headi-h1' style={{width: '800px', margin: '2rem 0', textAlign: 'center'}}>Core views on the relevance of AI to Social Behavior Change </h1>
        <p className='p__opensans' style={{ margin: '0rem 20rem', textAlign: 'center', }}>13 Feb 2024</p>
        {/* <button type='button' className='custom__button' style={{borderRadius: '12px', margin: '2rem 2rem'}}>Read Paper</button> */}
        
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensansa' style={{ margin: '2rem 5rem', width: '650px'}}>Poverty and its associated factors have a profound impact on populations, leading the
United Nations to establish Sustainable Development Goals (SDGs) with the aim of ending
poverty in all its forms by 2030. Poverty significantly affects health through unaffordability
of healthcare options, inaccessibility to information, lack of time for seeking care, and
compromised nutrition. <br/> <br/>

In Kenya, SDG Goals 3 and 5, which focus on ensuring healthy lives and promoting well-
being for all, as well as achieving gender equality, have not been fully achieved due to
various barriers.
These barriers include infrastructural/systemic, social, physical, and economic impediments
that hinder progress in achieving human rights, equity, and gender parity in health
programming among vulnerable populations in the country. These obstacles create a vicious
cycle that perpetuate an unjust system, leading to exclusive interventions that further
marginalize vulnerable communities.<br/> <br/>

Health policymakers and programs in Kenya face two significant challenges: determining
when to roll out health information campaigns to vulnerable populations and understanding
how health information spreads within the population to influence their behaviors. This is a
sub-domain commonly referred to as social behavior change (SBC) within the larger
population health domain.<br/> <br/>

Health literacy inequalities, often a result of these challenges, are prevalent among
vulnerable households, affecting decision-making, health-seeking behavior, and
prioritization among household genders. This directly impacts the uptake of SBC
interventions among these populations.<br/> <br/>

When conventional SBC interventions fail to optimally ignite positive health-seeking
behaviors, vulnerabilities and inequities become amplified, and the health burden grows.
To ensure that vulnerable populations receive the optimal care they deserve (as a human
right) and address the needs of users in making the right and timely healthcare decisions,
conventional processes relied upon by health programs must be updated to include the use
of AI and Large Language Models (LLMs).<br/> <br/>

We want to be at the forefront of the use of AI and LLMs in informing health decision-
making and messaging that considers the social, economic, cultural, and physiological needs
of households.<br/> <br/>

We believe LLMs can engender evidence-based SBC processes, support tailored
programmed interventions aimed at vulnerable households, and aid in faster decision
making to improve uptake and sustainability of disease prevention and control efforts in
Kenya.</p>
        </div>
        <p className='p__opensans' style={{ margin: '2rem 5rem', width: '600px', borderBottom: '1px solid #000',}}></p>
     </div>
    </div>
  </>
);

export default Soliii;
