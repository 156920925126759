import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import {
  AboutUs,
  Chef,
  Company,
  FindUs,
  Footer,
  Solution,
  Gallery,
  Header,
  Laurels,
  SpecialMenu,
} from "./container";
import { Navbar } from "./components";
import "./App.css";
import { Partners } from "./container/Partners/Partners";
import { Impact } from "./container/Impact/Impact";
import { OurWork } from "./container/OurWork/OurWork";

const Ap = () => (
  <>
    <div>
      <Header />
      {/* <SpecialMenu /> */}
      {/* <Impact /> */}
      {/* <Partners /> */}
      {/* <Stats /> */}
      <AboutUs />
      <OurWork />
      {/* <Company/> */}
      {/* <Stats /> */}
      {/* <Chef /> */}
      {/* <FindUs /> */}
    </div>
  </>
);

export default Ap;
