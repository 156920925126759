import React from 'react';
import CountUp from 'react-countup';
import { images } from '../../constants';

import './CompNews.css';
import { useNavigate } from 'react-router-dom';

export const CompNews= () => {
    const navigate = useNavigate();
  return (
    <>
        <div className='headText__cormorant'>
            <h2 className='headtexta__cormorant' style={{paddingTop: '0.6em', marginBottom: '-0.7em'}}>Company News</h2>
        </div>
        <div className="cardi-container" style={{paddingBottom: '5em'}}>
            
            <div className="cardi">
                <div className="cardi-content">
                    <div>
                        <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '1.57em',fontWeight: '500', color: '#000'}}><a href='#company' onClick={()=>{navigate('/solv')}}>InfoAFYA™ partner: Kenya NGOs Alliance Against Malaria.</a></p>
                    </div>
                    <div>
                        <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>12 Feb 2024</p>
                    </div>
                </div>
            </div>
            <div className="cardi">
                <div className="cardi-content">
                    <div>
                        <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '1.57em',fontWeight: '500', color: '#000'}}><a href='#company' onClick={()=>{navigate('/solvi')}}>DPE Company team member spotlight: Dennis Maorwe</a></p>
                    </div>
                    <div>
                        <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>12 Feb 2024</p>
                    </div>
                </div>
            </div>
            <div className="cardi">
                <div className="cardi-content">
                    <div>
                        <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '1.57em',fontWeight: '500', color: '#000'}}><a href='#company' onClick={()=>{navigate('/soliv')}}>We are Data Protection certified by the ODPC in Kenya</a></p>
                    </div>
                    <div>
                        <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>13 Feb 2024</p>
                    </div>
                </div>
            </div>

        </div>










    {/* <div className='app__header app__wrapper section__padding' id='company'>
            <div className='app__wrapper_infoz' style={{alignItems: 'center'}}>
                <div className='app__header app__wrapper section__padding'>
                    <div className='app__wrapper_infozx' style={{alignItems: 'center'}}>        
                        <div className='app__gala' >
                            <div className='headTexti__cormorant'>
                                <h2 className='p__cormorante' style={{fontWeight: '600'}}>Company News</h2>
                            </div>
                            <div className='app__galla flex__center'>
                                <div className='flexCenterd statsz'>
                                    

                                    <div className="flexColCenterzi stat">
                                        <div className='app__wrapper_imgz'>
                                            <p className='p__ope' style={{marginRight: '2em',padding: '0.08em', fontSize: '0.27em',fontWeight: '550', color: '#000'}}><a href='#company' onClick={()=>{navigate('/solv')}}>InfoAFYA™ partner: Kenya NGOs Alliance Against Malaria (KeNAAM).</a></p>
                                        </div>
                                        <div className='app__wrapper_imgz'>
                                            <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>12 Feb 2024</p>
                                        </div>
                                    </div>
                                    <div className="flexColCenterzi stat">
                                        <div className='app__wrapper_imgz'>
                                            <p className='p__ope' style={{marginRight: '2em',padding: '0.4em', fontSize: '0.27',fontWeight: '550', color: '#000'}}><a href='#company' onClick={()=>{navigate('/solvi')}}>DPE Company team member spotlight: Dennis Maorwe</a></p>
                                        </div>
                                        <div className='app__wrapper_imgz'>
                                            <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>12 Feb 2024</p>
                                        </div>
                                    </div>
                                    <div className="flexColCenterzi stat">
                                        <div className='app__wrapper_imgz'>
                                            <p className='p__ope' style={{marginRight: '2em',padding: '0.4em', fontSize: '0.27em',fontWeight: '550', color: '#000'}}><a href='#company' onClick={()=>{navigate('/soliv')}}>We are Data Protection certified by the ODPC in Kenya</a></p>
                                        </div>
                                        <div className='app__wrapper_imgz'>
                                            <p className='p__ope' style={{marginRight: '7em', color: '#79867b', fontSize: '12px', fontWeight: '400'}}>13 Feb 2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div> */}
    </>

 )
}
