import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import images  from '../../constants/images';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

function Footer(){
  const navigate = useNavigate();
  const emailAddress = 'admin@dpe.co.ke';
    return (
      <>
      <div className='app__header app__wrapper' id='company'>   
        <div className="cardiac-container" style={{paddingBottom: '0.1em'}} >   
            <div className="cardiac">
              <div className='app__footer'>
                <FooterOverlay />
                {/* <Newsletter /> */}
                <div className='app__footer-links'>
                  <div className='app__footer-links_logo'>
                      <img src={images.guchi} alt='footer_logo' />
                  </div> 

                  <div className='app__footer-links_contact'>

                  </div>

                  <div className='app__footer-links_logo'>
                    <p className='p__opensanse'>InfoAFYA&trade;</p>
                    <p className='p__opensanse' ><a href='#company' onClick={()=>{navigate('/soli')}}>Insights</a></p>
                    <p className='p__opensanse' style={{width: '150px'}}>Acceptable Use</p>
                    <div className='app__footer-links_icons'>
                    </div>
                  </div> 

                  <div className='app__footer-links_logo'>
                    <p className='p__opensanse'><a href='#company' onClick={()=>{navigate('/company')}}>Company</a></p>
                    <p className='p__opensanse'><a href={`mailto:${emailAddress}`}>Contact Us</a></p>
                    <p className='p__opensanse' style={{width: '150px'}}>Terms of Service</p>
                  </div> 

                  <div className='app__footer-links_logo'>
                    <p className='p__opensanse' style={{width: '150px'}}><a href='https://dpe.co.ke/privacy.html'>Privacy-Policy</a></p>
                    <p className='p__opensanse'>LinkedIn</p>
                  </div>

                  <div className='app__footer-links_work'>
                    <p className='p__opensanse'></p>
                  </div>

                  <div className='app__footer-links_work' style={{textAlign: 'right'}}>
                    <p className='p__opensanses'style={{width: '200px', fontSize: '1.2em'}}>&copy;2024 DPE Ltd</p>
                  </div>

                </div>
              </div>
            </div>
        </div>
      </div>

      </>
    )
  }
export default Footer;
