import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Solv.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Solv = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='company'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__headi-h1' style={{width: '800px', margin: '2rem 0', textAlign: 'center'}}>InfoAFYA™ partner spotlight: KeNAAM</h1>
        <p className='p__opensans' style={{ margin: '0rem 20rem', textAlign: 'center', }}>13 Feb 2024</p>
        {/* <button type='button' className='custom__button' style={{borderRadius: '12px', margin: '2rem 2rem'}}>Read Paper</button> */}
        
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensansa' style={{ margin: '2rem 5rem', width: '650px'}}>We are proud to highlight our key partner in the development, roll out, and governance of
            InfoAFYA, the Kenya NGOs Alliance Against Malaria (KeNAAM). <br/> <br/>
            <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>About KeNAAM</p>
            KeNAAM is a network of Civil Society Organizations (CSOs) providing Malaria and related
            health services in Kenya. The vision of KeNAAM is Malaria-free Kenya.<br/><br/>

            KeNAAM Offices are based in Nairobi, Kenya with active reach in the forty-seven (47)
            counties in Kenya. KeNAAM has partnered with like-minded organizations in Uganda,
            Tanzania, Somalia, Congo, Brazzaville and Bangladesh in the last 3 years.<br/><br/>

            KeNAAM is committed to scaling up effective malaria interventions and addressing related
            diseases and conditions among vulnerable communities in Kenya. The foundation of
            KeNAAM is driven by the realization that at that time, in 2001, no single institution in the
            country could singly reverse the devastating trends of Malaria hence the need to pool
            resources together to complement the Ministry of Health in tackling the disease.<br/><br/>

            Current membership for KeNAAM is over 100 organizations comprising Community-Based
            Organizations, Faith-Based Organizations, Non-Governmental Organizations, and
            International Organizations. <br/><br/>

            Since 2001, KeNAAM has been able to contribute to health and influence the
            implementation of public health systems and policies through the direct services provided
            to target communities by its members.  KeNAAM has a unique ability to interact with
            communities, react to community needs/issues and engage with affected and vulnerable
            groups. <br/><br/>

            KeNAAM&#39;s strength in the community systems strengthening initiatives aims at achieving
            improved outcomes for interventions to deal with major health challenges such as malaria,
            tuberculosis, HIV/AIDS, and other health system issues.<br/><br/>

            KeNAAM emphasizes strengthening community-based and community-led systems for
            prevention, treatment, care, and support; advocacy; and development of an enabling and
            responsive environment for communities.

          <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>KeNAAM’s experience is critical in the success of InfoAFYA due to the following factors:</p>

          <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM work to support the Ministry of Health complementing the Division of
              National Malaria Program (DNMP) in the development, review and implementation
              of policies, guidelines, and standard of malaria in Kenya.</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM Experience in identifying malaria key vulnerable population using tools
              such as Malaria Matchbox assessment which provide guidance on key risk factors

              and barriers impeding equitable malaria programs in Kenya, Bangladesh, Tanzania,
              Uganda, and Somalia.</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Key partner in the review, development, and implementation of the Kenya’s Malaria
              Communication Strategy plan</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM is a member of Kenya’s Committee of Expert on Social Behavior Change,
              thus has relationships to support seamless implementation of InfoAFYA™ solution.</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Previous experience designing and production of Malaria Social Behavior Change
              Communication materials for Ministry of Health, Merlin, KEMRI and projects such as
              FANIKISHA Institutional Strengthening Project and Kenya’s World Malaria Day
              Content over the years.</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM has been contracted by the Global Fund’s Community Rights and Gender
              Department to support provision of Technical Assistance to countries &amp; National
              Malaria programs which mostly involve demand creation for malaria services by
              communities and CSOs.</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM experience working with Persons with Disabilities by providing health
              content in formats that are PWD friendly, which are malaria vulnerable group
              identified during the matchbox assessment among other vulnerable population.</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM work in mapping CSOs in Kenya working with the Key Vulnerable
              Populations</p>
            </li>
            <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>KeNAAM’s work in supporting the delivery of the Malaria SBC Campaign for Key
                Vulnerable Populations, aiming to reaching over 12 million Kenyans by 2026.</p>
            </li>

          </ul><br/>
          In the recent past, DPE co-participated with KeNAAM in innovation support programs such
          as AMREF’s Innovation Now program, where the focus was on disability inclusive and
          assistive technology solutions, as well as the Global International Create Challenge (ICC)
          healthcare solutions by Idiap, a leading AI research center based in Martigny, Switzerland.
          <br/><br/>

          KeNAAM has been instrumental in supporting the research, development, and adoption of
          InfoAFYA™ digital services. Collaboratively, InfoAFYA’s team comprises of digital health
          specialists, social scientists, data engineers, product designers, software developers and
          community development teams made up of both DPE and KeNAAM talent.<br/><br/>

          We are extremely happy to continue our partnership with KeNAAM as InfoAFYA™ now
          enters a critical adoption phase.

</p>
        </div>
        <p className='p__opensans' style={{ margin: '2rem 5rem', width: '600px', borderBottom: '1px solid #000',}}></p>
     </div>
    </div>
  </>
);

export default Solv;
