import React from 'react';
import CountUp from 'react-countup';
import { images, data } from '../../constants';

import './Impact.css';

export const Impact= () => {
  return (
    <>  
        <div className='headText__cormorant'>
            <h2 className='headtexta__cormorant' style={{paddingTop: '0.7em'}}>Our Impact</h2>
        </div>
        <div className="card-container" style={{paddingBottom: '5em'}}>
            
            <div className="card">
                {/* <img src={images.find} alt='findus' /> */}
                <div className="card-content">
                    <h3>Over 1 Million People</h3>
                    {/* <a href='#' className='btn'>Read more</a> */}
                </div>
            </div>
            <div className="card">
                {/* <img src={images.find} alt='findus' /> */}
                <div className="card-content">
                    <h3>Tens of Health Partners</h3>
                    {/* <a href='#' className='btn'>Read more</a> */}
                </div>
            </div>
            <div className="card">
                {/* <img src={images.find} alt='findus' /> */}
                <div className="card-content">
                    <h3>Over 2 Million Engagements</h3>
                    {/* <a href='#' className='btn'>Read more</a> */}
                </div>
            </div>

        </div>

        
    </>
 )
}
