import React from 'react';
import CountUp from 'react-countup';

import './Usecase.css';

const Usecase = () => (
    <>        
        <div className='headText__cormorante' >
            <h2 className='p__cormorantez'>Features that Make life Easier</h2>
            <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
                <p className='p__opensansed' style={{ margin: '2rem 5rem'}}>InfoAFYA, Kenya's First Household Health Assistant Supporting Universal Health Coverage In Kenya. Health Assistant Supporting Universal Health Coverage In Kenya. Health Assistant Supporting Universal Health Coverage In Kenya.</p>
            </div>
        
            <div className='app__ga flex__center' style={{background: 'var(--color-crimson)', paddingBottom: '8rem'}}>
                <div className='flexCenter statse'>
                    <div className="flexColCent stati">
                        <span className='secondaryTexti'>Benefits</span>                    
                        <div>
                            <ul className='app__navbar-lin'>
                                <li className='p__opens'><a href='#home'>Colaboration</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="flexColCent stati">
                        <span className='secondaryTexti'>Interest</span>
                        <div>
                            <ul className='app__navbar-lin'>
                                <li className='p__opens'><a href='#home'>Messaging</a></li>
                            </ul>
                        </div>
                        
                    </div>

                    <div className="flexColCent stati">
                    <span className='secondaryTexti'>Quality</span>
                        <div>
                            <ul className='app__navbar-lin'>
                                <li className='p__opens'><a href='#home'>Data</a></li>
                            </ul>
                        </div>
                        
                    </div>

                    {/* <div className="flexColCent stati">
                        <span className='secondaryTexti'>Insights</span>
                        <div>
                            <ul className='app__navbar-lin'>
                                <li className='p__opens'><a href='#home'>Analysis</a></li>
                            </ul>
                        </div>
                        
                    </div> */}
                    
                </div>
            </div>
        </div>
    </>
 );

 export default Usecase;

