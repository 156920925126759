import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Purpose.css';

import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu,  MdOutlineClose } from 'react-icons/md';
import Gallery  from '../Gallery/Gallery';
import { Navbar } from '../../components';





const Purpose = () => (
  <>
  {/* <div className="wrapper">
    <header>
      <h1>Header</h1>
    </header>
    <aside>
        <h1>Aside</h1>
    </aside>
    <main>
      <h1>Main</h1>
    </main>
    <footer>
      <h1>Footer</h1>
    </footer>
  </div> */}

  <div className='wrap'>

  </div>



  <div className='app__header app__wrapper section__padding' id='company'>
    <div className='app__wrapper_info' style={{alignItems: 'center'}}>
      <div className='container app__bgi section__paddings' id='company'>
        
        <div className='contain'>
        {/* <div className='contain'> */}
            <div className='flex-item item-1 one app__wrapper_in'>
              {/* <h2 className='headtext__cormorantis' style={{fontSize: '2.4em'}}>Our Purpose</h2>  */}
              <h2 className='headtext__cormorantis' style={{fontSize: '2.4em', width: '8em'}}>Our Purpose</h2> 
            </div>
            
            <div className='flex-item item-2 two' style={{marginLeft: '0em', padding:'2em'}}>
              <div style={{ marginBottom: '1rem'}}>
                <p className='p__cormoranta' style={{fontSize: '3.1em',width: '15em', lineHeight: '1.08em',fontWeight: '610'}}>We believe in the power of technology in transforming people’s lives. We are
                  building people centered systems that lead to better population health
                  outcomes.</p>
              </div>
    
              <div className='app__chef-contenti appi'>
                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  
                  <div>
                    <p className='p__opensz'>Interdisciplinary</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We are a collaborative team of health policy reformists, software engineers, researchers and
                    business operators bringing our experience from many different domains
                    to our work. </p>
                  </div>
                </div>

                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  
                  <div>
                    <p className='p__opensz'>We build integrated systems</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We aim to build health solutions that are reliable, open and support
                    collaboration across various health domains. We experiment, develop, and deploy the resulting
                    solutions via a set of partnerships, products, and services.</p>
                  </div>
                </div>
              </div>

              <div className='app__chef-contenti appi'>
                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  
                  <div>
                    <p className='p__opensz'>The future is participatory</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>By co-designing and co-creating solutions with people and their communities, we enable them to take
                      control of their health. </p>
                  </div>
                </div>

                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                  
                  <div>
                    <p className='p__opensz'>We embrace open science</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We are committed to embracing open science principles to ensure ethical research practices.</p>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
          
      </div> 
    </div>
  </div>
 
  </>
);

export default Purpose;
