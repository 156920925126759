import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Company.css';

import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu,  MdOutlineClose } from 'react-icons/md';
import Header  from '../../container/Header/Header';
import { Navbar } from '../../components';





const Company = () => (
  
  <>
  <div className='app__header app__wrapper section__paddings' id='company'>
    <div className='app__wrapper_info' style={{alignItems: 'center'}}>
    
      <div className='container app__wrapperzia app__bgi section__paddings' id='company'>
        <div className='containz'>

            <div className='flex-item item-1 app__wrapper_inc ones'>
              <h2 className='headtext__cormorantis' style={{fontSize: '2.4em', width: '12em'}}>The Team</h2> 
            </div>
            

            <div className='flex-item item-2 twos' style={{marginLeft: '0em', padding: '2em'}}>
              <p className='p__cormoranta act' style={{fontSize: '1.9em',width: '26em', lineHeight: '1.19em',fontWeight: '400'}}>
                We’re a team of health policy experts, software engineers, researchers and business operators, with experience spanning a variety of disciplines, all working
                    together to develop next generation population health solutions.
              </p>      

              <div className='app__chef-contenti doe'>
                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                <img src={ images.val1} alt='header img' style={{marginTop: '1em'}} />
                  <div>
                    <p className='p__opensz'>Research</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We conduct research and explore emerging
                        areas where technology provides sustainable and desirable population health
                        outcomes.</p>
                  </div>
                </div>

                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                <img src={ images.val2} alt='header img' style={{marginTop: '1em'}}/>
                  <div>
                    <p className='p__opensz'>Policy</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We think about the impacts of our work and strive to communicate to policymakers and civil society in Kenya and regionally.</p>
                  </div>
                </div>
              </div>


              <div className='app__chef-contenti doe'>
                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                <img src={ images.val3} alt='header img' style={{marginTop: '1em'}} />
                  <div>
                    <p className='p__opensz'>Product</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>We translate our research into practical solutions such as InfoAFYA&trade; that benefit
                        health systems regionally.</p>
                  </div>
                </div>

                <div className='app__wrapper_imge' style={{ width: '30em'}}>
                <img src={ images.val4} alt='header img' style={{marginTop: '1em'}}/>
                  <div>
                    <p className='p__opensz'>Operations</p>
                    <p className='p__opensansz' style={{fontSize: '1.68em', lineHeight: '1.25em', letterSpacing: '0.06em'}}>Our finance, legal and project teams are the human engines that
                          make DPE tick. We’ve had previous careers at Oracle, Amref, KeNAAM, M-Tiba, SFL, 
                          working with other startups, and our diverse experiences and partnerships help make
                          our mission come to life.</p>
                  </div>
                </div>
              </div>
              
            </div>
        </div>
       
  </div>


    </div>
  </div>
  </>
);

export default Company;
