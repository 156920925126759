import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Solution.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Solution = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='home'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__header-h1' style={{ margin: '2rem 0', textAlign: 'center'}}>Meet InfoAFYA&trade; </h1>
        <p className='p__opensansx' style={{ margin: '0rem 20rem', textAlign: 'center', }}>InfoAFYA&trade;, Kenya's First Household Health Assistant Supporting Universal Health Coverage In Kenya.</p>
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensans' style={{ margin: '2rem 5rem'}}>For households, we understand convenience and control of the information you receive matters to you. InfoAFYA&trade; is a mobile-first solution for you and your family to remain informed always.</p>
        </div>
        <button type='button' className='custom__button' style={{ margin: '2rem 2rem'}}>Explore InfoAFYA&trade;</button>
      </div>

      {/* <div className='app__wrapper_img'>
        <img src={ images.gallery02} alt='header img' />
      </div> */}
    </div>
    <Usecase />
    {/* <Partners /> */}
    <div className='app__specialMenu flex__center section__padding' id='menu'>

      <div className='app__specialMenu-menu'>
        {/* <h1 className='headtext__cormorantd'>Our Solutions</h1> */}
        
        {/* <div className='app__specialMenu-menu_wine flex__center'>
          <p className='app__specialMenu-menu_heading'>For health systems, Interch</p>
          <img src={images.gerich} alt='logo' className='card-img' style={{maxWidth: '60%'}}/>
          <div className='app__specialMenu-menu_items'>
            {data.interch.map((interchs, index) => (
              <MenuItem key={interchs.title + index} title={interchs.title} price={interchs.price} tags={interchs.tags} />
            ))}
          </div>
          <p className='p__opensans'>Today's public health promotion approaches are fragmented and inconsistent, leaving consumers confused and with their needs unmet. Interch combines behavior science and Artificial Intelligence to power personalized health consumer experiences that improve outcomes, meaningfully and measurably. With Interch, say goodbye to costly point solutions that lead to inconsistent experiences for consumers seeking public health information.</p>
          <div style={{ marginTop: '15px', marginBottom: '15px'}}>
            <button type='button' className='custom__button'>View More</button>
          </div>
        </div> */}
        <div className='app__specialMenu-menu_cocktails flex__center'>
          {/* <p className='app__specialMenu-menu_heading'>InfoAFYA</p> */}
          <img src={images.geri} alt='logo' className='card-img' style={{maxWidth: '20%'}}/>
          <div className='app__specialMenu-menu_items'>
            {data.infoafya.map((infoafyas, index) => (
              <MenuItem key={infoafyas.title + index} title={infoafyas.title} price={infoafyas.price} tags={infoafyas.tags} />
            ))}
          </div>
          <p className='p__opensans'>For households, we understand convenience and control of the information you receive matters to you. InfoAFYA is a mobile-first solution for you and your family to remain informed always. </p>
          <div style={{ marginTop: '2.5rem', marginBottom: '15px'}}>
            <button type='button' className='custom__button'>View More</button>
          </div>
        </div>

      </div>

      

    </div>
    

  </>
);

export default Solution;
