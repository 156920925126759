import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Solii.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Solii = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='company'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__headi-h1' style={{width: '800px', margin: '2rem 0', textAlign: 'center'}}>InfoAFYA: A Comprehensive Solution towards Improved
Population Health Outcomes </h1>
        <p className='p__opensans' style={{ margin: '0rem 20rem', textAlign: 'center', }}>09 Feb 2024</p>
        {/* <button type='button' className='custom__button' style={{borderRadius: '12px', margin: '2rem 2rem'}}>Read Paper</button> */}
        
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
        
        <p className='p__opensansa' style={{ margin: '2rem 5rem', width: '600px'}}>
        {/* <p className='p__opensans' style={{fontWeight: '500', fontSize: '2em', paddingBottom: '1em'}}>Intro</p> */}
Population health management has become a critical priority for healthcare organizations,
policymakers, and researchers alike. However, implementing effective population health
initiatives presents numerous challenges, from collaboration among team members to
delivering personalized messages tailored to specific populations.
In this blog post, we will explore how our product manifesto can help healthcare
organizations overcome these challenges by providing a comprehensive solution for
improved outcomes.

      <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Collaborative Workflow</p>

Our collaborative workflow management system is designed to enable real-time
collaboration among team members, streamlining the intervention development process.
This modular solution architecture will also facilitate easy integration of new features and
modules, reducing the need for extensive code changes. Automated approval workflows will
minimize cognitive load and manual intervention, making it easier for healthcare
professionals, researchers, and policymakers to collaborate effectively and efficiently.
      <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Personalized Message</p>

Our product offers a library of pre-tested, evidence-based messages that can be easily
accessed and customized based on regional dynamics and user preferences. Our algorithms
provide personalized recommendations, ensuring that the right message is delivered to the

right person at the right time. By integrating with national frameworks such as eCHIS and
DHIS2, we can ensure accuracy in message delivery and design new campaign development
processes. Machine learning algorithms will improve the accuracy of persona modeling and
message personalization, further enhancing the effectiveness of our solution.

      <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Utilize Low Resource Constraint dissemination methods</p>

Utilize Low Resource Constraint dissemination methods:
We recognize that not all populations have access to digital health applications or other
high-tech tools. Therefore, we are developing low-resource constraint methods such as SMS
to increase reach and engagement among underserved communities. We hope we can
make our messages more engaging and persuasive, further increasing their effectiveness.

    <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Impact story telling</p>

Real-time metrics and visualization tools will improve impact storytelling by allowing us to
measure the effectiveness of our interventions and communicate the results in a compelling
and effective manner. This data will help healthcare organizations optimize their population
health management strategies and allocate resources more efficiently. We have a unique
way we want to go about this.
  
    <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Cost Savings and Risk Mitigation</p>

By reducing the cost associated with implementing multiple point solutions and manual
content creation and revision, our product will be a cost-effective choice for healthcare
organizations seeking to improve their population health management capabilities.
Our automated approval workflows and machine learning algorithms will minimize the risk
of errors, inconsistencies, missed opportunities, and ineffective or inappropriate content,
further reducing the risks associated with implementing population health initiatives.

<p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>In conclusion</p>


Our product offers a comprehensive solution for improved outcomes in population health
management by providing personalized, integrated, and impactful solutions that address
the challenges of collaboration, message delivery, low resource constraint methods, impact
storytelling, cost savings, and risk mitigation.
By streamlining campaign development processes, increasing engagement among
underserved communities, measuring effectiveness, and communicating results effectively,
we can help healthcare organizations achieve better outcomes for their populations. </p>
        </div>
        <p className='p__opensans' style={{ margin: '2rem 5rem', width: '600px', borderBottom: '1px solid #000',}}></p>
     </div>
    </div>
  </>
);

export default Solii;
