import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';
import AboutUs from '../AboutUs/AboutUs';
import Company from '../Company/Company';
import Purpose from '../Purpose/Purpose';
import Values from '../Values/Values';
import Newcomp from '../Newcomp/Newcomp';
import Header from '../Header/Header';
import ComHeader from '../ComHeader/ComHeader';
import { Impact } from '../Impact/Impact';
import { Partners } from '../Partners/Partners';
import { CompNews } from '../CompNews/CompNews';

const imagesz = [images.gallery01, images.gallery03, images.gallery04];

const Gallery = () => {
  return (
    <>
    <ComHeader />
    <Impact />
    {/* <Partners888888 /> */}
    <Purpose />
    <Company />
    {/* <Newcomp 999999/> */}
    <Values /> 
    <CompNews /> 
    </>
  );
}
export default Gallery;
