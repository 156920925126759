import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import {
  AboutUs,
  Chef,
  Company,
  FindUs,
  Footer,
  Gallery,
  Header,
  Laurels,
  SpecialMenu,
} from "./container";
import Ap from './Ap';
import { Navbar } from "./components";
import "./App.css";
import { Stats } from "./container/Stats/Stats";
import { Impact } from "./container/Impact/Impact";
import Blog from "./container/Blog/Blog";
import Solution from "./container/Solution/Solution";
import Soli from "./container/Soli/Soli";
import Solii from "./container/Solii/Solii";
import Soliii from "./container/Soliii/Soliii";
import Soliv from "./container/Soliv/Soliv";
import Solv from "./container/Solv/Solv";
import Solvi from "./container/Solvi/Solvi";

const App = () => (
  <>
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Ap />} />
      </Routes>
      <Routes>
        <Route path="/solution" element={<Solution />} />
      </Routes>
      <Routes>
        <Route path="/soli" element={<Soli />} />
      </Routes>
      <Routes>
        <Route path="/solii" element={<Solii />} />
      </Routes>
      <Routes>
        <Route path="/soliii" element={<Soliii />} />
      </Routes>
      <Routes>
        <Route path="/soliv" element={<Soliv />} />
      </Routes>
      <Routes>
        <Route path="/solv" element={<Solv />} />
      </Routes>
      <Routes>
        <Route path="/solvi" element={<Solvi />} />
      </Routes>
      <Routes>
        <Route path="/company" element={<Gallery />} />
      </Routes>
      <Routes>
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Footer />
    </div>
  </>
);

export default App;
