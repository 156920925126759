import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Soli.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Soli = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='company'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__headi-h1' style={{width: '800px', margin: '2rem 0', textAlign: 'center'}}>The DPE product Masterplan</h1>
        <p className='p__opensans' style={{ margin: '0rem 20rem', textAlign: 'center', }}>06 Feb 2024</p>
        {/* <button type='button' className='custom__button' style={{borderRadius: '12px', margin: '2rem 2rem'}}>Read Paper</button> */}
        
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensansa' style={{ margin: '2rem 5rem', width: '600px'}}>These programs
              have tackled specific health issues such as HIV, Maternal Health, Malaria, TB, to name a few.
              The programs have succeeded based on the narrow (in terms of scope and time) metrics
              that defined them. Due to this narrow focus, the programs have lacked a comprehensive,
              single-window view of the population’s health requirements and are therefore yet to make
              a holistic and long-lasting impact on envisaged health outcomes.
            </p>
        </div>
        <p className='p__opensans' style={{fontWeight: '600',fontSize: '1.9em',paddingTop: '0.3em', paddingBottom: '0em'}}>Background</p>
        

        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensansa' style={{ margin: '0rem 5rem', width: '600px'}}>Communities have a variety of health concerns impacting their health, productivity, and
              level of satisfaction. Therefore, health programs that are well-informed and targeted with
              thoughtful interventions developed collaboratively with the communities they seek to help,
              improves healthcare outcomes. Educating individuals and providing relevant information
              could mean huge benefits to the country.<br/><br/>

              Health Education is the provision of accurate and appropriate contextualized information on
              health aimed at assisting individual to make informed choices to improve their health. In
              delivering health education various communication approaches can be deployed ranging
              from use of one-on-one inter-personal communication, small group discussions to mass
              media in reaching the population.<br/><br/>

              The evolution in the delivery of health education has demonstrated over time that the
              availability of information does not necessarily translate to change. This has led to the
              adoption of health promotion that recognizes the need for an enabling environment as a
              critical component in achieving a behaviour desired change. Health promotion is holistic and
              examines the social, cultural, and economic context as enablers to behaviour change hence
              it engages individuals in identification of the barriers and facilitators to behaviour change.
              <br/><br/>
              The historical one-sidedness in healthcare investment has resulted in an inadequate focus
              on the demand side needs of healthcare end users. Despite the utilization of traditional
              media channels such as radio and TV to disseminate health information, they may not be
              timely or personalized enough, in comparison to what might be possible given today&#39;s
              growing demand side channels such as mobile phones.
              <br/><br/>
              Health planners also lack the necessary tools to manage and prioritize interventions on the
              demand side. Manual, time-consuming health promotion processes have resulted in
              inconsistent outcomes and often leave behind a population susceptible to infodemics and
              misinformation, and worst, neglect.
              <br/><br/>
              The historical one-sidedness in healthcare investment has resulted in an inadequate focus
              on the demand side needs of healthcare end users. Despite the utilization of traditional
              media channels such as radio and TV to disseminate health information, they may not be
              timely or personalized enough, in comparison to what might be possible given today&#39;s
              growing demand side channels such as mobile phones.
              <br/><br/>
              Health planners also lack the necessary tools to manage and prioritize interventions on the
              demand side. Manual, time-consuming health promotion processes have resulted in
              inconsistent outcomes and often leave behind a population susceptible to infodemics and
              misinformation, and worst, neglect.
              <br/><br/>
              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '0.3em', paddingBottom: '0em'}}>
                To that end, regionally, there are:
              </p>

            <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>No national brands to cater to the demand side of health promotion providers.</p></li>
              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>No tech enabled/hybrid service models supporting social behavior change programs.</p></li>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '550px'}}>No shared repository for available health information that can be reused and availed to
                end users on demand at national and county levels.</p>
              </li>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>No measurable ways to attribute the impact of health information to health investments</p></li>
            </ul>
            DPE believes that well informed users can participate in decision making processes and are
            empowered to make informed decisions in dialogue with their health care providers.
            Therefore, well informed users have the potential to improve the quality of content of care
            they receive.<br/><br/>

            The proliferation of handheld devices (mobile phones) in Kenya portends a lot for the health
            sector as it will continue to drive innovation in the sector in terms of digital
            health/technologies and communication.<br/><br/>

            DPE through its INTERCH&trade; platform and its InfoAFYA&trade; health assistant service wants to be at
            the fore front of innovation in the region by developing an effective behaviour change
            intervention mechanism through targeted communication which will be used to improve
            people’s knowledge and attitudes towards healthy behaviours and utilization of health
            services.<br/><br/>

            <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '0.3em', paddingBottom: '0em'}}>
              DPE intends to solve these challenges
            </p>
            <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '600px'}}>
                By providing a solution that is compelling, scalable, and user-friendly, health systems
                (supply side) can address the unique needs and preferences of dynamic populations.</p>
              </li>
              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '600px'}}>
                By providing a unified experience across different channels, health systems can help
                individuals overcome the barriers and act towards positive behavior changes (demand
                side).</p>
              </li>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '600px'}}>
                By leveraging user- friendly workflows that help health systems develop targeted
                behaviour change initiatives, resulting in faster deployment of health promotion
                campaigns.</p>
              </li>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '600px'}}>
                By providing a solution with the ability to learn about user’s behaviour through powerful
                machine learning technology to optimise user engagement and therefore health
                outcomes and facilitate innovation</p>
              </li>
            </ul>

              INTERCH&trade; is a software solution that empowers communities and individuals to take
              control of their health. It does this by simplifying the design, delivery, and consumption of
              social behaviour change interventions.<br/><br/>

              In the next 5 years, we aim to be the leading comprehensive population health engagement
              technology platform for end users and providers, engaging with over half of the East African
              population to reduce the healthcare burden and improve their lives.<br/>
              
              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '0em', paddingBottom: '0em'}}>
                <img src={images.gerich} alt='footer_logo' style={{width: '10em', height: '8em', borderRadius: '50%'}} />
              </p>

              The services will be offered through INTERCH&trade; , a population
              health management software for scalable behaviour change
              management that gives InfoAFYA&trade; most of it’s capabilities.
              <br/><br/>

              INTERCH&trade; empowers communities and individuals to take
              control of their health. INTERCH&trade; and InfoAFYA&trade; do this by
              simplifying the design, delivery, and consumption of social
              behaviour change interventions.
              <br/><br/>

              In the next 5 years, we aim to grow INTERCH&trade; to be the leading population health
              engagement technology platform for end users and providers, engaging with over half of
              the Kenyan and East African population to reduce the healthcare burden.

              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '0.6em', paddingBottom: '0em'}}>
                The major milestones that we need to achieve to reach our product vision are:
              </p>
              
              <br/>
              Reduce the time taken to plan and roll out a population health campaign with key
              outcomes such as:

            <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>
                Increase collaboration in the design and roll out of public health campaigns by:<br/>
                
                a. Increase frequency and ease with which campaigns are designed and rolled
                out, collaboratively.</p>
              </li>

              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                Reduce the amount of cognitive overload required to collaborate.<br/>

                a. Reduce the complexity through guided workflows, self-service tools, etc.<br/>

                b. Make it easier to generate insights from data. </p>
              </li>
            </ul>
            <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '0.8em', paddingBottom: '0em'}}>
              Target the right people with the right intervention with key outcomes such as:
            </p>

            <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>
                Promote the profile of intervention end users.<br/>
                a. Make it very easy to define the “intended target population” for better
                message innovation</p>
              </li>

              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                Ensure the right messages are designed for the right person or recipient.<br/>
                a. Make it very easy to design evidence-based SBC messages.</p>
              </li>

              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                Map the evolving health information landscape of our intervention users to highlight
                emerging health literacy inequalities.</p>
              </li>
            </ul>

            <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '0.8em', paddingBottom: '0em'}}>
               Increased utilization of the right messages with key outcomes such as:
            </p>

            <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
              <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>
                Focus on engagement models that work well especially in low resource
                environments.</p>
              </li>

              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                Make it easy to track utilization of SBC messages to beneficiaries.</p>
              </li>

              <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                Diversify messages consumption beyond current channels and avenues to increase
                the accessibility of SBC messaging.</p>
              </li>
            </ul>

              INTERCH&trade; will be utilized to disseminate unidirectional and bidirectional targeted health
              content (malaria, pregnancy) to pre-defined at-risk populations tailored to individuals,
              based on static and dynamic information about recipients from routine health surveys
              registry system.

              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1.0em', paddingBottom: '0em'}}>
                Expected INTERCH&trade; /InfoAFYA&trade; benefits
              </p><br/>
                Optimizing Client/User Engagement:

              <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
                <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>
                  Target the right audience with the right messages efficiently: InfoAFYA&trade; is designed to help
                  you identify and reach out to the individuals who need your services the most, ensuring
                  that you can make a significant impact on their lives quickly.</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Develop Person-centered communication for effective behavior change: By focusing on
                  personalized interactions, our system fosters a supportive environment that encourages
                  positive social behavior changes. This results in higher resource adoption rates and
                  contributes to a healthier population overall.</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Adaptability for long-term success: In today&#39;s rapidly changing world, staying relevant and
                  meeting the evolving needs of clients or users is crucial. Our platform is flexible and
                  adaptable, allowing you to adjust to their needs in real-time, ensuring continued
                  engagement and satisfaction.</p>
                </li>
              </ul>

              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '0em'}}>
                Closing Care Gaps Quickly:
              </p>

              <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
                <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>
                  Prioritize behavior change for improved outcomes: By making behavior change a top
                  priority in care delivery, you can ensure that your beneficiaries receive the support they
                  need to adopt better health seeking behaviors</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Identify and address high-risk vulnerabilities: Our platform helps you identify and mitigate
                  potential issues along the care continuum, provide targeted interventions and improve
                  overall patient outcomes.</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Reach out to vulnerable populations with ease: We understand that not all beneficiaries
                  have the same needs or preferences when it comes to communication and support. Our
                  platform offers a variety of user-friendly formats, making it easy for health systems to
                  connect with vulnerable and at-risk populations and provide them with the care they
                  need.</p>
                </li>
              </ul>

              <p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1.0em', paddingBottom: '0em'}}>
                Enhancing SBC Interventions Outcomes Measurably:
              </p>

              <ul style={{alignItems: 'left',listStyle: 'numbers', padding: '10px', borderRadius: '5px' }}>
                <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>
                  Monitor progress and measure success: By tracking results that demonstrate meaningful
                  change, you can gain valuable insights into the effectiveness of your behavior change
                  interventions. This information helps you make data-driven decisions and refine your
                  strategies for continued improvement.</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Report on impact with clarity and precision: Our platform enables health systems to
                  showcase the progress made in their social behavior change initiatives in a way that is
                  easy to understand. This transparency fosters trust and encourages collaboration with
                  all stakeholders.</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Streamline message development and reusability: Our intuitive platform makes it simple
                  to find, reuse, and adapt existing messages, reducing anxiety associated with content
                  creation and allowing health systems to focus on delivering impactful communication
                  strategies.</p>
                </li>

                <li><p className='p__opensansc' style={{alignItems: 'left', margin: '0.5rem 1rem', width: '550px'}}>
                  Supporting communities through collaboration: By leveraging our platform&#39;s collaborative
                  features, health systems can work together with other professionals, organizations, and
                  end users to develop and share best practices, resources, and insights that promote
                  better health outcomes for all.</p>
                </li>
              </ul>

          </p>
        </div>
        <p className='p__opensans' style={{ margin: '2rem 5rem', width: '600px', borderBottom: '1px solid #000',}}></p>
     </div>
    </div>
  </>
);

export default Soli;
