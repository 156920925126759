import React from 'react';


import { images } from '../../constants'; 
import './Header.css';
import { SubHeading } from '../../components';
import { useNavigate} from 'react-router-dom';


function Header(){
  const navigate = useNavigate();
  return (
  <>
  
    <div className='app__header app__bg app__wrappery section__paddi' id='home'>
      <div className="carda-container" style={{paddingBottom: '9.7em'}} >   
          <div className="carda">
            <div className='app__wrapper_infoz' style={{alignItems: 'center'}}>
              <h1 className='app__header-h1' style={{paddingBottom: '0.3em', width: '70%', marginTop: '1em'}}>DPE is a health technology and innovation company</h1>
              <p className='p__opensansx' style={{paddingBottom: '2em', width: '48%'}}>Our InfoAFYA&trade; solution reduces the anxiety and headache for households looking for reliable and trusted public health information.<br /></p>
              <button type='button' className='custom__button'><a href='https://www.infoafya.com/demo'>Learn more about InfoAFYA&trade;</a></button>
            </div>
          </div>
      </div>
      
  </div>
    
  </>
  )
}

export default Header;
