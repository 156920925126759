import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { Card } from '../../components/Card/Card.jsx'
import { images, data } from '../../constants';
import './Soliv.css';
import { Partners } from '../Partners/Partners';
import Usecase from '../Usecase/Usecase';

const Soliv = () => (
  <>
  
    <div className='app__header app__wrapper section__padding' id='company'>
      <div className='app__wrapper_info' style={{alignItems: 'center', paddingBottom: '8.5rem'}} >
        {/* <SubHeading title='' /> */}
        <h1 className='app__headi-h1' style={{width: '800px', margin: '2rem 0', textAlign: 'center'}}>Celebrating Our Data Protection Certification from the Office of
the Data Protection Commissioner (ODPC) in Kenya</h1>
        <p className='p__opensans' style={{ margin: '0rem 20rem', textAlign: 'center', }}>13 Feb 2024</p>
        {/* <button type='button' className='custom__button' style={{borderRadius: '12px', margin: '2rem 2rem'}}>Read Paper</button> */}
        
        <div className='app__ga flex__center' style={{background: 'var(--color-crimson)'}}>
            <p className='p__opensansa' style={{ margin: '2rem 5rem', width: '650px'}}>As a company dedicated to improving population health in Kenya, we understand the
importance of data protection and privacy for our clients and partners. We are thrilled to
announce that we have recently received the Data Protection Certification from the Office
of the Data Protection Commissioner (ODPC) in Kenya. This certification acknowledges our
commitment to maintaining the highest standards of data protection and privacy
compliance. <br/> <br/>

<p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>The Importance of Data Protection</p>
In today&#39;s digital age, personal information has become a valuable asset. Ensuring that
sensitive health data is protected is crucial for building trust with our clients, partners, and
the community at large. By obtaining this certification from the ODPC, we demonstrate our
dedication to upholding the principles of transparency, fairness, and accountability in our
data handling practices.<br/> <br/>

<p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>The Certification Process</p>

Obtaining the Data Protection Certification involved a rigorous assessment process
conducted by the ODPC. This evaluation covered various aspects of our organization,
including our policies, procedures, and systems for managing personal data. The assessment
ensured that we meet the requirements set forth in Kenya&#39;s Data Protection Act, 2019, and
adhere to best practices for maintaining data privacy and security.
The DPA 2019 imposes a requirement to conduct a Data Protection Impact Assessment
(DPIA) where applicable. It imposes an obligation to conduct a DPIA in cases where the
processing of personal data could result in a high risk to the rights and freedoms of a data
subject. A DPIA, therefore, assesses such risks and offer ways to mitigate them.
The assessment made in the DPIA builds on the systematic analysis made in guidance
documents published by the ODPC.<br/>

<p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '0em'}}>This DPIA includes the following steps:</p>
  <ul style={{alignContent: 'left',listStyle: 'numbers', padding: '5px', borderRadius: '5px' }}>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Threshold analysis – determining whether personal data processing activities require
        an impact assessment;</p>
    </li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Description of the envisioned processing – e.g. identification, characterisation and
        description of applications processing personal data in InfoAFYA™;</p>
    </li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Assessment of the necessity and proportionality;</p></li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Measures already taken;</p></li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Assessment of the risks to the rights and freedoms;</p></li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Measures envisioned to address risks;</p></li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Documentation;</p></li>
    <li><p className='p__opensansc' style={{ margin: '0.5rem 1rem', width: '560px'}}>Monitoring and review - according to the DPA 2019, there is no mandatory
        timeframe to follow when reviewing the DPIA process.</p>
    </li>

  </ul>
<br/>
The review of a DPIA should
be carried out by the controller, where necessary, to assess if processing is
performed in accordance with the DPIA at least when there is a change in the risk
represented by processing operations. In situations where there are multiple parties
(e.g. several controllers can join the processing operations at different points in
time) there is no need to conduct a new DPIA, if such scalability of the project has
been considered at its inception. More guidance in this respect is provided by the
regulators.<br/> <br/>

A new or revised version of DPIA could be required if the risks resulting from the processing
operations change, for example because a new technology has been introduced or because
personal data is being used for a different purpose.
Data processing operations can evolve quickly, and new vulnerabilities can arise. Therefore,
the revision of a DPIA is not only useful for continuous improvement, but also critical to
maintain the level of data protection in a changing environment over time. A new DPIA may
also become necessary because the organizational or societal context for the processing
activity has changed, for example because the effects of certain automated decisions have
become more significant, or new categories of data subjects become vulnerable to
discrimination.
Each of these examples could be an element that leads to a change in the risk analysis
concerning the processing activity at hand. Conversely, certain changes could lower the risk
as well. For example, a processing operation could evolve so that decisions are no longer
automated or if a monitoring activity is no longer systematic. In that case, the review of the
risk analysis made can show that the performance of a DPIA is no longer required.
To this end, we were awarded the Data Controller and Data Processor certifications.<br/> <br/>

We want to be at the forefront of the use of AI and LLMs in informing health decision-
making and messaging that considers the social, economic, cultural, and physiological needs
of households.<br/> <br/>

<p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>What This Means for Our Solution, Clients and Partners</p>

As a company focused on improving population health in Kenya, we recognize that trust is
essential in our work. By achieving the ODPC&#39;s Data Protection Certification, we reassure our
clients and partners that their personal information is safe with us. They can rely on us to
handle their data responsibly and in accordance with the highest standards of privacy and
security.<br/><br/>

<p className='p__opensans' style={{fontWeight: '600', fontSize: '1.2em',paddingTop: '1em', paddingBottom: '1em'}}>Looking Ahead</p>

Obtaining the Data Protection Certification from the ODPC is a significant milestone for our
company. It reaffirms our dedication to maintaining the highest standards of data
protection and privacy while working in the population health space in Kenya. We would like
to express our gratitude to the ODPC for their guidance throughout the certification
process. As we move forward, we remain committed to staying at the forefront of data
privacy compliance and incorporating best practices into every aspect of our operations.

</p>
        </div>
        <p className='p__opensans' style={{ margin: '2rem 5rem', width: '600px', borderBottom: '1px solid #000',}}></p>
     </div>
    </div>
  </>
);

export default Soliv;
